import React, { useState, useEffect } from "react";
import classes from './LegendDetails.module.css'
import { useNavigate } from "react-router-dom";
import { Button, Carousel } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import Obj from '../../assets/legends-icon/obj1.png'
import Obj1 from '../../assets/legends-icon/obj2.png'
import Label from '../../assets/legends-icon/label.png'
import footerLogo from "../../assets/Footer-logo.svg"

// function App() {
const LegendDetails = () => {
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
    const latestYear = new Date().getFullYear();
    setCurrentYear(latestYear);
  }, []); 






  return (
    <div>

      <HeaderNav />

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <div className={classes.topDetails}>
            <div className={classes.topHeader}>
              <h5 className={classes.headerText}>CHIEF <br />OLUSEGUN OBASANJO</h5>
              <p>Chief Olusegun Matthew Okikiola Ogunboye Aremu Obasanjo GCFR  is a Nigerian retired military officer and statesman
                who served as Nigeria's head of state from 1976 to 1979 and later as its president from 1999 to 2007. Ideologically a
                Nigerian nationalist, he was a member of the Peoples Democratic Party (PDP) from 1998 to 2015, and since 2018.</p>
              <p>Born in the village of Ibogun-Olaogun to a farming family of the Owu branch of the Yoruba, Obasanjo was educated
                largely in Abeokuta, Ogun State. He joined the Nigerian Army and specialised in engineering and was assigned to the
                Congo, Britain, and India, rising to the rank of major. In the late 1960s, he played a senior role in combating Biafran
                separatists during the Nigerian Civil War, accepting their surrender in 1970. In 1975, a military coup established a junta
                with Obasanjo as part of its ruling triumvirate. After the triumvirate's leader, Murtala Muhammed, was assassinated the
                following year, the Supreme Military Council[3] appointed Obasanjo as head of state.

                Continuing Murtala's policies, Obasanjo oversaw budgetary cut-backs and an expansion of access to free school
                education. Increasingly aligning Nigeria with the United States, he also emphasised support for groups opposing
                white minority rule in southern Africa. Committed to restoring democracy, Obasanjo oversaw the 1979 election, after
                which he transferred control of Nigeria to the newly elected civilian president, Shehu Shagari. Obasanjo then retired to
                Ota, Ogun, where he became a farmer, published four books, and took part in international initiatives to end various
                African conflicts.</p>
                <div className={classes.label}>
                <p className={classes.labelText1}>FORMER PRESIDENT OF THE FEDERAL REPUBLIC OF NIGERIA</p>
                </div>
            </div>
              <img
                src={Obj}
                alt="Obasanjo"
                style={{ objectFit: 'cover', width: 700, height: 600, marginTop: 39 }}
              />
          </div>

        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <div className={classes.topDetails}>
          <img
                src={Obj1}
                alt="Obasanjo"
                style={{ objectFit: 'cover', width: 400, height: 500, marginTop: 39 }}
              />
            <div className={classes.topHeader}>
              <h5 className={classes.headerText1}>LIFE OF CHIEF <br />
OLUSEGUN OBASANJO</h5>
              <p>In 1993, Sani Abacha seized power in a military coup. Obasanjo was openly critical of Abacha's 
administration and in 1995 was arrested and convicted of being part of a planned coup, despite 
protesting his innocence. While imprisoned, he became a born again Christian, with 
providentialism strongly influencing his subsequent worldview. He was released following 
Abacha's death in 1998. 

Obasanjo entered electoral politics, becoming the PDP candidate for the 1999 presidential 
election which he won comfortably. As president, he de-politicised the military and both 
expanded the police and mobilised the army to combat widespread ethnic, religious, and 
secessionist violence. He withdrew Nigeria's military from Sierra Leone and privatised various 
public enterprises to limit the country's spiralling debt. He was re-elected in the 2003 election. 

Influenced by Pan-Africanist ideas, he was a keen supporter of the formation of the African 
Union and served as its chair from 2004 to 2006. Obasanjo's attempts to change the constitution 
to abolish presidential term limits were unsuccessful and brought criticism. After retiring, he 
earned a PhD in theology from the National Open University of Nigeria.</p>
              <p>Obasanjo has been described as one of the great figures of the second generation of  post-colonial African leaders. He received praise both for overseeing Nigeria's transition to  representative democracy in the 1970s and for his Pan-African efforts to encourage cooperation across the continent. Critics maintain that he was guilty of corruption, that his administrations oversaw human rights abuses, and that as president he became too interested in consolidating and maintaining his personal power</p>
            </div>
              
          </div>

        </div>
      </div>












      <div className={`${classes.wrapper} ${classes.sectionEleven}`}>
        <div className={classes.ogunMap}>
          <h2 className={classes.omoOgun}>OMO OGUN - ISEYA</h2>
        </div>
      </div>


      <div className={`${classes.wrapper} ${classes.FooterBg}`}>
        <div className={`${classes.container} ${classes.mainFooter}`}>
          {/* <div className={classes.finalDiv}> */}
          <div className={classes.footerLogoCont}>
            <div className={classes.footerLogo}>
              <img src={footerLogo} alt="Ogun Logo" />
            </div>
            <p style={{ color: 'white' }}>
              Ogun State is a tapestry of diverse cultures,
              with the Egba, Ijebu, Ilishan, Remo, and
              Sagamu peoples all tracing their roots to
              historic Egbaland. Experience the warmth
              of our people, explore ancient landmarks,
              and immerse yourself in the vibrant energy
              of our hospitality.

              Ogun State welcomes you!
            </p>
          </div>

          <div className={`${classes.footerConts} ${classes.footerContsnav}`}>
            <h5 className={classes.name}>Link</h5>
            <ul>
              <li>Home</li>
              <li>Event</li>
              <li>Blog</li>
              <li>News</li>
              <li>Archives</li>
            </ul>
          </div>

          <div className={classes.footerConts}>
            <h5 className={classes.name}>MDAs</h5>
            <ul>
              <li>Bureau of Public service reforms</li>
              <li>Ministry of Education, Science and Technology</li>
              <li>Ministry of Budget and Planning</li>
              <li>Ministry of Political Affairs and Administration</li>
            </ul>
          </div>
          <div className={classes.footerConts}>
            <h5 className={classes.name}>Contact Information</h5>
            <ul>
              <li>Government House complex, Oke-Mosan, Abeokuta, Ogun State</li>
              <li>info@ogunstate.gov.ng</li>
            </ul>
          </div>


        </div>
      </div>
      <div className={classes.footerText}>
      <p className={classes.subFooter}>© OGUN STATE GOVERNMENT {currentYear}</p>
  <p className={classes.subFooter1}>All Rights Reserved. Terms of Use and Privacy Policy</p>
</div>
    </div>
  );
}

export default LegendDetails;