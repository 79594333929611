import React, { useState, useEffect } from "react";
import classes from './Executives.module.css'
import { useNavigate } from "react-router-dom";
import { Button, Carousel } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import Dapo from "../../assets/jt.jpg";
import NewFooter2 from "../../Components/NewFooter2";

// function App() {
const ExecutivesDetails3 = () => {
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
    const latestYear = new Date().getFullYear();
    setCurrentYear(latestYear);
  }, []);



  return (
    <div>

      <HeaderNav />

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <h1>MR. JOSEPH OLATOKUNBO TALABI</h1>
          <p>SECRETARY TO THE STATE GOVERNMENT</p>
          <div className={classes.bioContainer}>
          <div className={classes.imageCont}>
          <img
            src={Dapo}
            alt="SSG"
            style={{ objectFit: 'contain', width: "100%", height: 500, marginTop: 39 }}
          />
          </div>
          <div className={classes.profileCont}>
          <div className={classes.topSectionLabel}>
            <h5 className={classes.labelText}>PROFILE OF MR. JOSEPH OLATOKUNBO TALABI</h5>
            <div className={classes.profileText}>
              <p>Joseph Olatokunbo Talabi is a highly accomplished and distinguished professional with a remarkable career spanning multiple industries. Born on June 16, 1962, into the esteemed family of Pa Olanrewaju and Madam Comfort Adebimpe Talabi from Oru-Ijebu, Ijebu-North Local Government Area, Ogun State, Nigeria, he embodies excellence and a strong work ethic.<br /> <br />

              Olatokunbo's educational journey began at the Lagos City Council School, Fadeyi, Lagos, where he completed his elementary education. He pursued his secondary education at the prestigious CMS Grammar School in Bariga, Lagos, from 1975 to 1980. With exceptional academic performance, he achieved outstanding results in the WAEC Final examinations, earning the coveted Grade One. Notably, he received accolades as CMS Grammar School's top Mathematics and Technical Drawing student and was honored with the Professor C.O. Taiwo's Awards for Excellence in Mathematics.<br /> <br />
              In 1980, Olatokunbo gained admission to the University of Ife, now known as Obafemi Awolowo University (OAU), where he pursued a Bachelor's Degree in Medical Rehabilitation (Physiotherapy) and successfully graduated in 1984. Subsequently, he completed his National Youth Service Corps (NYSC) at the Physiotherapy Department of the State Hospital in Akure. Demonstrating his commitment to continuous personal growth, he pursued further studies and obtained an MBA (Finance) from the University of Lagos in 1987. He embarked on his professional journey in 1987, the same year he received his MBA as an advertising executive at "The Nigerian Economist," a prominent financial newspaper owned by the esteemed journalist Mallam Haroun Adamu. In 1988, he joined Bedola Enterprises Ltd, a banking Automation Equipment Sale/Services dealer, as an Administrative Manager. However, his passion for banking led him to transition to a career at Guaranty Trust Bank Plc in 1990. As one of the pioneering staff members, he initially assumed the role of Assistant Manager and subsequently held various leadership positions over his eight-year tenure. He served as the Head of Corporate Support Services, led the Financial Institution’s Loan Workout Units, and advanced to Senior Manager in Corporate Banking. Eventually, Olatokunbo's expertise and dedication led him to the prestigious role of Group Head, Retail Banking Operations, where he effectively managed branch operations nationwide.<br /> <br />
              In 1998, driven by an entrepreneurial spirit and a desire to explore new opportunities, Olatokunbo made the courageous decision to leave his promising banking career at Guaranty Trust Bank. This marked the beginning of his successful entrepreneurial journey and the establishment of Superflux International Limited. Leveraging his remarkable acumen, Olatokunbo's Midas touch propelled Superflux International Limited to become the country agent for Kalamazoo Security Prints, a renowned UK-based organization. In 2005, Superflux International Limited erected its first local manufacturing facility, and by 2008, it had constructed the largest security printing factory in West Africa, with additional capacities established in Ghana.<br /> <br />
              Throughout the years, Olatokunbo's investment and entrepreneurial prowess led him to become a significant stakeholder in various sectors of the Nigerian economy. He demonstrated versatility by venturing into logistics, finance, insurance, agriculture, telecommunications, and real estate. As a result, the Superflux group now encompasses multiple enterprises, including Superflux International Limited, Courierplus Services Limited (a leading courier and logistics company in Nigeria), Flux Pay (a payment platform), Atco Limited (specializing in farming and agro services), and Flux Logistix Limited (Nigeria's largest self-storage facility manager and a prominent warehousing and distribution services company).<br /> <br />
              Beyond his entrepreneurial endeavors, Olatokunbo actively contributed his expertise in various board capacities. He served as the Chairman of Great Nigeria Insurance Plc. from 2013 to 2015 and co-founded, directed, and chaired Bitflux Communications Limited from 2014 to 2021. Additionally, he held a directorial position at Vdt Communications Limited from 2014 to 2021 and assumed the role of Chairman at Risk Control Services Nig. Limited. Olatokunbo also served as a Director in esteemed institutions such as Investment One Services Limited, Reddington Multi-Specialist Hospital, Mama Cass Restaurants Limited, Redcare Services Limited (HMO), Touchdown Travels Limited, Proximity Communications Nig. Limited, FATE Foundation, City of Knowledge Academy (CKA), and SCORE (a sickle cell support initiative based in the UK).<br /> <br />
              Due to his exceptional expertise and dedication, Olatokunbo was appointed in 2019 as the Secretary to the State Government of Ogun, tasked with contributing to the implementation of the "Building Our Future Together" Agenda of His Excellency, Prince Dapo Abiodun CON, the Governor of Ogun State. Consequently, he relinquished his positions on all the aforementioned boards to focus on this critical responsibility.<br /> <br />
              Olatokunbo's professional affiliations include prestigious associations such as the National Association of Small & Medium Scale Enterprises (NASME), where he serves as the Deputy National President and the Chartered Institute of Professional Printers of Nigeria. He also holds the distinguished position of Entrepreneur in Residence at the Entrepreneurial Development Centre of the Lagos Business School, showcasing his commitment to fostering entrepreneurial development.<br /> <br />
              In recognition of his outstanding contributions, Olatokunbo has received numerous awards and prizes throughout his career. Notably, he was awarded the Legatum Pioneers of Prosperity Africa Awards Grand Prize in 2008 and named the FATE Foundation Entrepreneur of the Year in 2009. Furthermore, Olatokunbo is a published author, with his widely acclaimed book, "The Mind's Game - An Inspiring Guide for Entrepreneurial Success," released in 2012.<br /> <br />
              Outside the professional realm, Olatokunbo cultivates a rich and fulfilling personal life. His hobbies include cycling, table tennis, reading, teaching, and mentoring. He is happily married to Aderinola, and together, they are blessed with three children. Olatokunbo’s exemplary professional achievements, unwavering dedication, and entrepreneurial prowess have solidified his status as a top-class individual in his field. His exceptional leadership, broad industry expertise, and commitment to driving positive change continue to inspire and shape Nigeria's business landscape.<br /> <br />
              </p>
              
            
            </div>
          </div>
          </div>
          </div>
          
        </div>
      </div>

   


     













      <NewFooter2 />
    </div>
  );
}

export default ExecutivesDetails3;