import React, { useState, useEffect } from "react";
import classes from './Executives.module.css'
import { Link, useNavigate } from "react-router-dom";
import { Button, Carousel, Spinner } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import Dapo from "../../assets/executives-icon/dapoo.png";
import Noimot from "../../assets/executives-icon/noimot.png";
import CommissionerIcon from "../../assets/executives-icon/icon.png";
import SSG1 from "../../assets/ssg/talabi.png";
import HOS1 from "../../assets/ssg/Hos.jpg";
import HOS2 from "../../assets/ssg/dhos.jpg";
import SSG2 from "../../assets/ssg/afolabi.png";
import COMM1 from "../../assets/commissioners/a.png";
import COMM2 from "../../assets/commissioners/b.png";
import COMM3 from "../../assets/commissioners/c.png";
import COMM4 from "../../assets/commissioners/d.png";
import COMM5 from "../../assets/commissioners/e.png";
import COMM6 from "../../assets/commissioners/f.png";
import COMM7 from "../../assets/commissioners/g.png";
import COMM8 from "../../assets/commissioners/h.png";
import COMM9 from "../../assets/commissioners/i.png";
import COMM10 from "../../assets/commissioners/j.png";
import COMM11 from "../../assets/commissioners/k.png";
import COMM12 from "../../assets/commissioners/l.png";
import footerLogo from "../../assets/Footer-logo.svg"
import NewFooter from "../../Components/NewFooter";
import NewFooter2 from "../../Components/NewFooter2";
import { BASE_URL, TOKEN } from "../../BaseUrl/BaseUrl";
import axios from "axios";

// function App() {
const Executives = () => {
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState('');
  const [documents, setDocuments] = useState([]);
  const [documents1, setDocuments1] = useState([]);
  const [documents2, setDocuments2] = useState([]);
  const [documents3, setDocuments3] = useState([]);
  const [documents4, setDocuments4] = useState([]);
  const [documents5, setDocuments5] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState("");

  const fetchExecutives = async () => {
    setLoading(true);
    try {
        const response = await axios.get(`${BASE_URL}/executives-data`, {
          headers: {
            'Authorization': `Bearer ${TOKEN}`,
            'Content-Type': 'application/json'
        },
            // params: {
            //     page: currentPage
            // }
        });
        const results = response?.data?.data;
        // const results1 = response?.data?.data?.Chief_of_Staff;
        const results2 = response?.data?.data?.Level_3;
        const results3 = response?.data?.data?.Level_1;
        const results4 = response?.data?.data?.Level_2;
        const results5 = response?.data?.data?.Level_4;
        setDocuments(results);
        // setDocuments1(results1);
        setDocuments2(results2);
        setDocuments3(results3);
        setDocuments4(results4);
        setDocuments5(results5);
        console.log(results);
        // setTotalEntries(response?.data?.data?.total);
        // const total = response?.data?.data?.last_page || 1;
        // setTotalPages(total);
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
  fetchExecutives();
}, [currentPage]);


  useEffect(() => {
    const latestYear = new Date().getFullYear();
    setCurrentYear(latestYear);
  }, []);

 
  const handleViewDetails = (item) => {
    navigate('/executive_profile',  {state: { data: item }});
  };



  return (
    <div>

      <HeaderNav />
      <div className={classes.overflow}>
        {loading ? (
          <div className={classes.spinnerContainer}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className={classes.wrapper}>
              {documents3.map((item, index) => (
                <div key={index} className={classes.topSection}>
                  <h1>{item.commisioners_name}</h1>
                  <div className={classes.mobilePadding}>
                    <img
                      src={item.commisioners_image}
                      alt="Ogun State Governor"
                    />
                    <div className={classes.topSectionLabel}>
                      <h5 className={classes.labelText}>{item.portfolio === "Governor" ? "Governor of Ogun State" : item.portfolio}</h5>
                      <div>
                        <p className={classes.commentP}>{item.description} </p>
                        <div onClick={() => handleViewDetails(item)} className={classes.readMore}><p>Read more...</p></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={classes.wrapper}>
              {documents4.map((item, index) => (
                <div key={index} className={classes.topSection1}>
                  <h1>{item.commisioners_name}</h1>
                  <div className={classes.mobilePadding}>
                    <div className={classes.sectionLabel}>
                      <div className={classes.deputyImg}>
                        <img
                          src={item.commisioners_image}
                          alt="Deputy Governor of Ogun State"
                          className={classes.imgss}
                        />
                      </div>
                      <div className={classes.labelSection}>
                        <h5 className={classes.labelText}>{item.portfolio}</h5>
                        <p className={classes.commentP}>{item.description} </p>
                        <div onClick={() => handleViewDetails(item)} className={classes.readMore1}><p>Read more...</p></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={classes.wrapper}>
              <div className={classes.topSection1}>
                <h1 className={classes.commissioner}></h1>
                <div className={classes.mobilePadding}>
                  <div className={classes.comLabel}>
                    {documents2.map((item, index) => (
                      <div key={index} className={`${classes.comHead} ${classes.comHeadAdj1}`}>
                        <div className={classes.imgConts}>
                          <img
                            src={item.commisioners_image}
                            alt="Commissioner"
                            className={`${classes.imgs} ${classes.cmr1}`}
                          />
                        </div>
                        <h5 className={classes.commLabel}>{item.commisioners_name}</h5>
                        <p className={classes.posLabel}>{item.portfolio} </p>
                        <div onClick={() => handleViewDetails(item)} className={classes.readMore2}><p>Read more...</p></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.wrapper}>
              <div className={classes.topSection1}>
                <h1 className={classes.commissioner}>COMMISSIONERS</h1>
                <div className={classes.mobilePadding}>
                  <div className={classes.comLabel}>
                    {documents5.map((item, index) => (
                      <div key={index} className={`${classes.comHead} ${classes.comHeadAdj1}`}>
                        <div className={classes.imgConts}>
                          <img
                            src={item.commisioners_image}
                            alt="Commissioner"
                            className={`${classes.imgs} ${classes.cmr1}`}
                          />
                        </div>
                        <h5 className={classes.commLabel}>{item.commisioners_name}</h5>
                        <p className={classes.posLabel}>{item.portfolio.toLocaleUpperCase()}, {item.name} </p>
                        <div onClick={() => handleViewDetails(item)} className={classes.readMore2}><p>Read more...</p></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <NewFooter2/>
    </div>
  );
}

export default Executives;