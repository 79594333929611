import React, { useState } from "react";
import HeaderNav from "../../Components/HeaderNav/HeaderNav";
import NewFooter from "../../Components/NewFooter";
import classes from './Archive.module.css';
import { Form, Row, Col, FormGroup } from "react-bootstrap";
import DocIcon from '../../assets/doc.png'



function Archive() {
    const [selectedOption, setSelectedOption] = useState('');

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (

        <div>
            <HeaderNav />
            <div className={classes.archvTable}>
                {/* <p className={classes.tablep}>Archives</p> */}
                <div className={classes.mainContainter}>
                    <div className={classes.leftContainer}>
                        <h5 className={classes.filt}>Filter</h5>
                        <Form.Group className={classes.formGroup} >
                            <Form.Label className={classes.label}>MDA:</Form.Label>
                            <Form.Select >
                                <option>Ministry</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className={classes.formGroup} >
                            <Form.Label className={classes.label}>Type:</Form.Label>
                            <Form.Select >
                                <option>Ministry of Justice</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className={classes.formGroup} >
                            <Form.Label className={classes.label}>Year:</Form.Label>
                            <Form.Select >
                                <option>2022</option>
                            </Form.Select>
                        </Form.Group>

                        <button className={classes.dwnldbtn1}>Search</button>
                    </div>
                    <div className={classes.rightContainer}>
                        <h1 className={classes.archHeader}>Archives</h1>
                        <p className={classes.archLabel}>Showing result for Documents uploaded for the Ministry of Justice in 2022</p>
                        <div className={classes.archContainer}>
                            <img src={DocIcon} alt="Ogun Logo" />
                            <div className={classes.archSub}>
                                <h5 className={classes.archTitle}>TITLE</h5>
                                <p className={classes.archSubText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                                <p>Uploaded on: 25th January 2024 by 12:8pm</p>
                            </div>
                            <button className={classes.dwnldbtn2}>Download</button>
                        </div>
                        <div className={classes.archContainer}>
                            <img src={DocIcon} alt="Ogun Logo" />
                            <div className={classes.archSub}>
                                <h5 className={classes.archTitle}>TITLE</h5>
                                <p className={classes.archSubText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                                <p>Uploaded on: 25th January 2024 by 12:8pm</p>
                            </div>
                            <button className={classes.dwnldbtn2}>Download</button>
                        </div>
                        <div className={classes.archContainer}>
                            <img src={DocIcon} alt="Ogun Logo" />
                            <div className={classes.archSub}>
                                <h5 className={classes.archTitle}>TITLE</h5>
                                <p className={classes.archSubText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                                <p>Uploaded on: 25th January 2024 by 12:8pm</p>
                            </div>
                            <button className={classes.dwnldbtn2}>Download</button>
                        </div>
                        <div className={classes.archContainer}>
                            <img src={DocIcon} alt="Ogun Logo" />
                            <div className={classes.archSub}>
                                <h5 className={classes.archTitle}>TITLE</h5>
                                <p className={classes.archSubText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                                <p>Uploaded on: 25th January 2024 by 12:8pm</p>
                            </div>
                            <button className={classes.dwnldbtn2}>Download</button>
                        </div>
                        <div className={classes.archContainer}>
                            <img src={DocIcon} alt="Ogun Logo" />
                            <div className={classes.archSub}>
                                <h5 className={classes.archTitle}>TITLE</h5>
                                <p className={classes.archSubText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                                <p>Uploaded on: 25th January 2024 by 12:8pm</p>
                            </div>
                            <button className={classes.dwnldbtn2}>Download</button>
                        </div>
                        <div className={classes.archContainer}>
                            <img src={DocIcon} alt="Ogun Logo" />
                            <div className={classes.archSub}>
                                <h5 className={classes.archTitle}>TITLE</h5>
                                <p className={classes.archSubText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                                <p>Uploaded on: 25th January 2024 by 12:8pm</p>
                            </div>
                            <button className={classes.dwnldbtn2}>Download</button>
                        </div>
                        <div className={classes.archContainer}>
                            <img src={DocIcon} alt="Ogun Logo" />
                            <div className={classes.archSub}>
                                <h5 className={classes.archTitle}>TITLE</h5>
                                <p className={classes.archSubText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                                <p>Uploaded on: 25th January 2024 by 12:8pm</p>
                            </div>
                            <button className={classes.dwnldbtn2}>Download</button>
                        </div>
                    </div>

                </div>
            </div>






            {/* <NewFooter /> */}
        </div>


    )
}
export default Archive;