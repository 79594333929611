import React, { useState, useEffect } from "react";
import classes from './Executives.module.css'
import { Link, useNavigate } from "react-router-dom";
import { Button, Carousel } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import Dapo from "../../assets/executives-icon/dapoo.png";
import Noimot from "../../assets/executives-icon/noimot.png";
import CommissionerIcon from "../../assets/executives-icon/icon.png";
import gov1 from '../../assets/govImg1.png';
import gov2 from '../../assets/f1.png';
import gov3 from '../../assets/f2.png';
import gov4 from '../../assets/f3.png';
import arm from '../../assets/arm.png';
import arm2 from '../../assets/arm2.png';
import NewFooter from "../../Components/NewFooter";
import footerLogo from "../../assets/Footer-logo.svg"
import NewFooter2 from "../../Components/NewFooter2";

// function App() {
const Government = () => {
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
    const latestYear = new Date().getFullYear();
    setCurrentYear(latestYear);
  }, []);


  const handleFinance = () => {
    navigate('/financial_statements')
  }
  const handleAuditor = () => {
    navigate('/state_auditors_report')
  }
  const handleLocalGovernment = () => {
    navigate('/local_govt_financial_reports')
  }
  const handleMtef = () => {
    navigate('/mtef_reports')
  }
  const handleOgun = () => {
    navigate('/ogsiec')
  }
  const handleProcurement = () => {
    navigate('/public_procurement')
  }
  const handleTender = () => {
    navigate('/public_procurement')
  }
  

  return (
    <div>

      <HeaderNav />
      <div className={classes.overflow}>

      
      <div className={classes.wrapper}>
       <div className={classes.main}>
        <div className={classes.mains}>
          <div className={classes.header}>
            <p className={classes.opentxt}>Open Government</p>
            <p className={classes.under}>The Ogun State Government is a strong proponent of the open government partnership - one that combines the powerful forces of government leaders, civil society and the citizenry to promote
                accountable, responsive and inclusive governance. In line with Nigeria's 2019 -2021 action plan, the state government is committed to implementing the following:</p>
            <div className={classes.containers}>
              <div className={classes.citizen}>
                  <img src={gov1} className={classes.gov1}/>
                  <p className={classes.citizenTxt}>Citizen participation in the<br/>
                    budget cycle</p>
              </div>

              <div className={classes.citizen}>
                  <img src={gov2} className={classes.gov1}/>
                  <p className={classes.citizenTxt}>Open contracting.<br/>
                  Executive sector transparency</p>
              </div>

              <div className={classes.citizen}>
                  <img src={gov3} className={classes.gov1}/>
                  <p className={classes.citizenTxt}>Tax<br/>
                    Reporting Standards</p>
              </div>
              
              <div className={classes.citizen}>
                  <img src={gov4} className={classes.gov1}/>
                  <p className={classes.citizenTxt}>World Bank Ease<br/>
                  of Doing Business Index</p>
              </div>

            </div>
            <p className={classes.endTxt}>The push by the Ogun State Government to actively participate in the above commitments will ensure that the State Government is more collaborative with the citizens, and the citizenry are also adequately informed.</p>
          </div>

          <div className={classes.billLaws}>
            <div className={classes.bill1}>
              <div className={classes.search}>
                  <img src={arm} className={classes.arm}/>
                  <div onClick={handleOgun} className={classes.txtpo}>
                    <p className={classes.searchTxt}>Ogun State Local Government Election 2024</p>
                    <p className={classes.srcUndr}>Get the result of the Ogun State Local Government Election 2024.</p>
                  </div>
              </div>
              <div className={classes.search}>
                  <img src={arm} className={classes.arm}/>
                  <div className={classes.txtpo}>
                    <p className={classes.searchTxt}>Ogun Constitution</p>
                    <p className={classes.srcUndr}>The Ogun state constitution consists of laws that are codified</p>
                  </div>
              </div>
              <div className={classes.search}>
                  <img src={arm} className={classes.arm}/>
                  <div className={classes.txtpo}>
                    <p className={classes.searchTxt}>Approved Treaties</p>
                    <p className={classes.srcUndr}>Here are up to date enquiries on agreements, pacts, major treaties, between
                      tribes and ethnic groups within the state.</p>
                  </div>
              </div>

            </div>
            <div className={classes.next}>
              <p className={classes.down}>Bills & Laws</p>
            </div>
          </div>

        </div>


          <div className={classes.stateOfBudgetMain}>
            <div className={classes.nex}>
              <p>State
              Budget</p>
            </div>

            <div className={classes.bill2}>
              <div className={classes.search2}>
                  <img src={arm2} className={classes.arm2}/>
                  <div onClick={handleMtef} className={classes.txtpo}>
                    <p className={classes.searchTxt2}>Ministry of Budget & Planning Documents</p>
                    <p className={classes.srcUndr2}>MTEF Report, Budget Assessments, etc</p>
                  </div>
              </div>
              {/* <div className={classes.search2}>
                  <img src={arm2} className={classes.arm2}/>
                  <div onClick={handleMtef} className={classes.txtpo}>
                    <p className={classes.searchTxt2}>MTEF Report</p>
                    <p className={classes.srcUndr2}>Get updates on Medium-Term Expenditure Framework Report.</p>
                  </div>
              </div> */}
              {/* <div className={classes.search2}>
                  <img src={arm2} className={classes.arm2}/>
                  <div onClick={handleMtef} className={classes.txtpo}>
                    <p className={classes.searchTxt2}>Budget Assessments</p>
                    <p className={classes.srcUndr2}>Get Informed on issues related to the state's budget.</p>
                  </div>
              </div> */}

            </div>
          </div>

          <div className={classes.mains}>
              <div className={classes.billLaws}>
                <div className={classes.bill1}>
                  <div className={classes.search}>
                      <img src={arm} className={classes.arm}/>
                      <div onClick={handleFinance} className={classes.txtpo}>
                        <p className={classes.searchTxt}>State Financial Report</p>
                        <p className={classes.srcUndr}>Get access to Ogun State comprehensive annual reports, receipt reporting and<br/>
                            other financial reports.</p>
                      </div>
                  </div>
                  <div className={classes.search}>
                      <img src={arm} className={classes.arm}/>
                      <div onClick={handleAuditor} className={classes.txtpo}>
                        <p className={classes.searchTxt}>State Auditors Report</p>
                        <p className={classes.srcUndr}>An evaluation of the state’s financial entity</p>
                      </div>
                  </div>
                  <div className={classes.search}>
                      <img src={arm} className={classes.arm}/>
                      <div onClick={handleLocalGovernment} className={classes.txtpo}>
                        <p className={classes.searchTxt}>Local Government Financial Reports</p>
                        <p className={classes.srcUndr}>An open repository of Ogun’s expenditures</p>
                      </div>
                  </div>

                </div>
                <div className={classes.next}>
                  <p className={classes.down}>Financial <br /> Statements</p>
                </div>
              </div>
          </div>

          <div className={classes.stateOfBudgetMain}>
            <div className={classes.nex}>
              <p>Public Data-
              Bureau of Statistics</p>
            </div>

            <div className={classes.bill2}>
              <div className={classes.search2}>
                  <img src={arm2} className={classes.arm2}/>
                  <div className={classes.txtpo}>
                    <p className={classes.searchTxt2}>Search State Online Publications</p>
                    <p className={classes.srcUndr2}>Search the state database for written publications, case studies, keynote<br/>
                      speeches, etc on areas of interest within Ogun state</p>
                  </div>
              </div>
              <div className={classes.search2}>
                  <img src={arm2} className={classes.arm2}/>
                  <div className={classes.txtpo}>
                    <p className={classes.searchTxt2}>State Statistics Annual Report</p>
                    <p className={classes.srcUndr2}>Lookup Ogun's annual reports</p>
                  </div>
              </div>
              <div className={classes.search2}>
                  <img src={arm2} className={classes.arm2}/>
                  <div className={classes.txtpo}>
                    <p className={classes.searchTxt2}>Statistical Releases</p>
                    <p className={classes.srcUndr2}>Ogun provide open access to a publicly available dataset, maps and<br/>
                    statistics.</p>
                  </div>
              </div>
            </div>
          </div>
          <div className={classes.mains}>

          <div className={classes.billLaws}>
            <div className={classes.bill1}>
              <div className={classes.search}>
                  <img src={arm} className={classes.arm}/>
                  <div onClick={handleTender} className={classes.txtpo}>
                    <p className={classes.searchTxt}>Bureau of Public Procurement</p>
                    <p className={classes.srcUndr}>Contracts, Tender, Public Procurement, and e.t.c.<br/>
                    </p>
                  </div>
              </div>
              {/* <div onClick={handleProcurement} className={classes.search}>
                  <img src={arm} className={classes.arm}/>
                  <div className={classes.txtpo}>
                    <p className={classes.searchTxt}>Bureau of Public Procurement</p>
                    <p className={classes.srcUndr}>Ogun bureau of public procurement is responsible for Monitoring and<br/>
                      Harmonizing the existing policies.</p>
                  </div>
              </div> */}
              {/* <div className={classes.search}>
                  <img src={arm} className={classes.arm}/>
                  <div className={classes.txtpo}>
                    <p className={classes.searchTxt}>Contracts Awarded</p>
                    <p className={classes.srcUndr}>Stay updated on recently awarded contracts in Ogun state.</p>
                  </div>
              </div> */}

            </div>
            <div className={classes.next}>
              <p className={classes.down}>Procurement</p>
            </div>
          </div>
          </div>

      </div>
      </div>

      </div>
      <NewFooter2 />
    </div>
  );
}

export default Government;