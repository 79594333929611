import React, { useState } from "react";
import HeaderNav from "../../Components/HeaderNav/HeaderNav";
import NewFooter from "../../Components/NewFooter";
import classes from './Archive.module.css';
import { Form, Row, Col, FormGroup, Button } from "react-bootstrap";
import DocIcon from '../../assets/pdff.png';
import PdfFile from '../../../src/doc/pdf1.pdf';
import PdfFile2 from '../../../src/doc/pdf2.pdf';
import PdfFile3 from '../../../src/doc/pdf3.pdf';
import PdfFile4 from '../../../src/doc/pdf4.pdf';
import PdfFile5 from '../../../src/doc/pdf5.pdf';
import PdfFile6 from '../../../src/doc/pdf6.pdf';
import PdfFile7 from '../../../src/doc/pdf7.pdf';
import FilterIcon from '../../assets/FilterIcon.svg'
import PdfFile8 from '../../../src/doc/pdf8.pdf';
import NewFooter2 from "../../Components/NewFooter2";


function ArchiveNew() {
    const [selectedOption, setSelectedOption] = useState('');
    const pdfFileName = 'REOI - Consultancy to Conduct a Formative Survey';
    const pdfFileName1 = 'REOI - Consultancy to Produce a Short Movie';
    const pdfFileName2 = 'RFQ- Development of MIS, Technical College Entrance Application';
    const pdfFileName3 = 'OGUN STATE ECONOMIC TRANSFORMATION PROJECT';
    const pdfFileName4 = 'REOI Consultancy for the Supevision RVFF PC 12052024';
    const pdfFileName5 = 'Upgrade for EMIS solution';
    const pdfFileName6 = ' RFQ -SARC EQUIPMENT';
    const pdfFileName7 = 'REOI_-_Consultancy_to_Develop_ESMP_for_22_Schools-_13052024';
    // const pdfFileName8 = 'REOI_-_Consultancy_to_Develop_ESMP_for_22_Schools-_13052024';
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const toggleFilter = () => {
        setIsFilterOpen(!isFilterOpen); // Toggle the menu open/close state
    };
    
    const closeFilter = () => {
    setIsFilterOpen(false); // Close the menu
    };


    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (

        <div>
            <HeaderNav />
            <div className={classes.archvTable}>
                {/* <p className={classes.tablep}>Archives</p> */}
                <div className={classes.mainContainter}>
                    <div className={classes.leftContainer}>
                        <div className={classes.filterText} onClick={toggleFilter}>
                            <h5 className={classes.filt} >Filter</h5>
                            <img src={FilterIcon} alt="Icon"/>
                            {/* <span className={classes.filterIcon}>icon</span> */}
                        </div>
                        <div className={`${classes.filterBox} ${isFilterOpen? '':classes.unActive}`}>
                            <Form.Group className={classes.formGroup} >
                                <Form.Label className={classes.label}>MDA:</Form.Label>
                                <Form.Select >
                                    <option>Ministry</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className={classes.formGroup} >
                                <Form.Label className={classes.label}>Type:</Form.Label>
                                <Form.Select >
                                    <option>Ministry of Justice</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className={classes.formGroup} >
                                <Form.Label className={classes.label}>Year:</Form.Label>
                                <Form.Select >
                                    <option>2022</option>
                                </Form.Select>
                            </Form.Group>

                            <button className={classes.dwnldbtn1}>Search</button>
                        </div>
                    </div>
                    <div className={classes.rightContainer}>
                        <h1 className={classes.archHeader}>CONTRACTS & ADVERTS</h1>
                        {/* <p className={classes.archLabel}>Showing result for Documents uploaded for the Ministry of Justice in 2022</p> */}
                        <div className={classes.archivGco}>
                        <div className={classes.archContainer}>
                            <div className={classes.archiveFlex}>
                                <img src={DocIcon} alt="Ogun Logo" style={{ height: 70, width: 70 }}/>
                                <div className={classes.archSub}>
                                    <h5 className={classes.archTitle}>{pdfFileName}</h5>
                                    <p className={classes.archSubText}>Engagement of Consultant to Conduct a Formative Survey on understanding parents' and
                                        students’ perception of TVET colleges and for development and delivery of gender-sensitive
                                        socioemotional skills training
                                        (OG/CS/PIU/02/2024)
                                    </p>
                                </div>
                                <a href={PdfFile} target="blank" className={`${classes.dwnldbtn2} ${classes.webShow}`}>Download</a>
                            </div>
                            
                            <div className={classes.directions}>
                                <p>Uploaded on: 10th May, 2024 by 12:08pm</p>
                                <a href={PdfFile} target="blank" className={`${classes.dwnldbtn2} ${classes.mobileShow}`}>Download</a>
                            </div>
                        </div>
                        <div className={classes.archContainer}>
                            <div className={classes.archiveFlex}>
                                <img src={DocIcon} alt="Ogun Logo" style={{ height: 70, width: 70 }}/> 
                                <div className={classes.archSub}>
                                    <h5 className={classes.archTitle}>{pdfFileName1}</h5>
                                    <p className={classes.archSubText}>Engagement of Consultant to produce a short movie focusing on women's earning potential
                                        in male-dominated trades
                                        OG/CS/PIU/01/2024
                                        </p>
                                </div>
                                <a href={PdfFile2} target="blank" className={`${classes.dwnldbtn2} ${classes.webShow}`}>Download</a>
                            </div>
                            <div className={classes.directions}>
                                <p>Uploaded on: 10th May, 2024 by 12:08pm</p>
                                <a href={PdfFile2} target="blank" className={`${classes.dwnldbtn2} ${classes.mobileShow}`}>Download</a>
                            </div>
                        </div>
                        <div className={classes.archContainer}>
                            <div className={classes.archiveFlex}>
                                <img src={DocIcon} alt="Ogun Logo" style={{ height: 70, width: 70 }}/>
                                <div className={classes.archSub}>
                                    <h5 className={classes.archTitle}>{pdfFileName2}</h5>
                                    <p className={classes.archSubText}>Development of Scholarship Management Information System
                                        (MIS), Technical College Entrance Application Website, and
                                        Offline Digital Technical College Entrance Application Form
                                        OG/CS/PIU/03/2024</p>
                                </div>
                                <a href={PdfFile3} target="blank" className={`${classes.dwnldbtn2} ${classes.webShow}`}>Download</a>
                            </div>
                            <div className={classes.directions}>
                                <p>Uploaded on: 10th May, 2024 by 12:08pm</p>
                                <a href={PdfFile3} target="blank" className={`${classes.dwnldbtn2} ${classes.mobileShow}`}>Download</a>
                            </div>
                        </div>
                        <div className={classes.archContainer}>
                            <div className={classes.archiveFlex}>
                                <img src={DocIcon} alt="Ogun Logo" style={{ height: 70, width: 70 }}/>
                                <div className={classes.archSub}>
                                    <h5 className={classes.archTitle}>{pdfFileName3}</h5>
                                    <p className={classes.archSubText}>PROCUREMENT OF EQUIPMENT FOR STATE ASSAULT REFERRAL CENTRES IN ILARO AND ASERO OG/GO/SARC/01/2024</p>
                                </div>
                                <a href={PdfFile4} target="blank" className={`${classes.dwnldbtn2} ${classes.webShow}`}>Download</a>
                            </div>
                            <div className={classes.directions}>
                                <p>Uploaded on: 10th May, 2024 by 12:08pm</p>
                                <a href={PdfFile4} target="blank" className={`${classes.dwnldbtn2} ${classes.mobileShow}`}>Download</a> 
                            </div>
                        </div>
                        <div className={classes.archContainer}>
                            <div className={classes.archiveFlex}>
                                <img src={DocIcon} alt="Ogun Logo" style={{ height: 70, width: 70 }}/>
                                <div className={classes.archSub}>
                                    <h5 className={classes.archTitle}>{pdfFileName4}</h5>
                                    <p className={classes.archSubText}>OGUN STATE ECONOMIC TRANSFORMATION PROJECT (OGSTEP)
                                    REQUEST FOR EXPRESSIONS OF INTEREST</p>
                                </div>
                                <a href={PdfFile5} target="blank" className={`${classes.dwnldbtn2} ${classes.webShow}`}>Download</a>
                            </div>
                            <div className={classes.directions}>
                                <p>Uploaded on: 10th May, 2024 by 12:08pm</p>
                                <a href={PdfFile5} target="blank" className={`${classes.dwnldbtn2} ${classes.mobileShow}`}>Download</a>
                            </div>
                        </div>
                        <div className={classes.archContainer}>
                            <div className={classes.archiveFlex}>
                                <img src={DocIcon} alt="Ogun Logo" style={{ height: 70, width: 70 }}/>
                                <div className={classes.archSub}>
                                    <h5 className={classes.archTitle}>{pdfFileName5}</h5>
                                    <p className={classes.archSubText}>Upgrade of the Education Management Information System
                                    (EMIS) Solution for the Ministry of Education Science &
                                    Technology, Ogun State
                                    OG/NC/SDS/01/2024
                                    </p>
                                </div>
                                <a href={PdfFile6} target="blank" className={`${classes.dwnldbtn2} ${classes.webShow}`}>Download</a>
                            </div>
                            
                            <div className={classes.directions}>
                                <p>Uploaded on: 10th May, 2024 by 12:08pm</p> 
                                <a href={PdfFile6} target="blank" className={`${classes.dwnldbtn2} ${classes.mobileShow}`}>Download</a>
                            </div>
                        </div>
                        <div className={classes.archContainer}>
                            <div className={`${classes.archiveFlex} ${classes.archiveFlexNewFles}`}>
                                <img src={DocIcon} alt="Ogun Logo" style={{ height: 70, width: 70 }}/>
                                <div className={`${classes.archSub} ${classes.textAdjustSize}`}>
                                    <h5 className={`${classes.archTitle} ${classes.h5Resize}`}>{pdfFileName7}</h5>
                                        <p className={classes.archSubText}>Engagement of Individual Consultant to Develop ESMP for the Rehabilitation of Laboratories in 22 selected Secondary schools and 4 Educational Resource centers in Ogun State OG/CS/PIU/04/2024
                                    </p>
                                </div>
                                <a href={PdfFile8} target="blank" className={`${classes.dwnldbtn2} ${classes.webShow}`}>Download</a>
                            </div>
                            
                            <div className={classes.directions}>
                            <p>Uploaded on: 10th May, 2024 by 12:08pm</p> 
                                <a href={PdfFile6} target="blank" className={`${classes.dwnldbtn2} ${classes.mobileShow}`}>Download</a>
                            </div>
                        </div>
                        {/* <div className={classes.archContainer}>
                            <div className={classes.archiveFlex}>
                                <img src={DocIcon} alt="Ogun Logo" />
                                <div className={classes.archSub}>
                                    <h5 className={classes.archTitle}>{pdfFileName7}</h5>
                                    <p className={classes.archSubText}>Engagement of Individual Consultant to Develop ESMP for the Rehabilitation of Laboratories in 22 selected Secondary schools and 4 Educational Resource centers in Ogun State OG/CS/PIU/04/2024
                                    </p>
                                </div>
                                <a href={PdfFile8} target="blank" className={`${classes.dwnldbtn2} ${classes.webShow}`}>Download</a>
                            </div>
                            
                            <div className={classes.directions}>
                                <p>Uploaded on: 10th May, 2024 by 12:08pm</p> 
                                <a href={PdfFile6} target="blank" className={`${classes.dwnldbtn2} ${classes.mobileShow}`}>Download</a>
                            </div>
                        </div> */}
                        <div className={`${classes.archContainer} ${classes.archContainerLast}`}>
                            <div className={classes.archiveFlex}>
                                <img src={DocIcon} alt="Ogun Logo" style={{ height: 70, width: 70 }}/>
                                <div className={classes.archSub}>
                                    <h5 className={classes.archTitle}>{pdfFileName6}</h5>
                                    <p className={classes.archSubText}>PROCUREMENT OF EQUIPMENT FOR STATE ASSAULT
                                    REFERRAL CENTRES IN ILARO AND ASERO
                                    OG/GO/SARC/01/2024

                                    </p>
                                </div>
                                <a href={PdfFile7} target="blank" className={`${classes.dwnldbtn2} ${classes.webShow}`}>Download</a>
                            </div>
                            <div className={classes.directions}>
                                <p>Uploaded on: 10th May, 2024 by 12:08pm</p>
                                <a href={PdfFile7} target="blank" className={`${classes.dwnldbtn2} ${classes.mobileShow}`}>Download</a>
                            </div>
                        </div>

                        
                        </div>
                        
                        
                    </div>

                </div>
                {/* <Button></Button> */}
                <div className= {classes.empty}>
                </div>
            </div>
            <NewFooter2 />
        </div>


    )
}
export default ArchiveNew;
