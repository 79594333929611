import React from "react";
import { NavLink } from 'react-router-dom';
import { Navhead } from "../../Components/NavHeader/Navhead";
import { Footer } from "../../Components/Footer/Footer";
import NewFooter from "../../Components/NewFooter";
import UrbanHeader from "../../Components/urbanHeader/UrbanHeader";
import murp from "../../assets/murp-herobanner.png"
import MOA from "../../assets/MOA-herobanner.png"
import aboutline from "../../assets/aboutline.png"
import kad1 from "../../assets/kadgrn.png"
import kad2 from "../../assets/kadpeach.png"
import kad3 from "../../assets/kadgrn3.png"
import awrdrcgntn from "../../assets/awardsrecognition.png"
import awrdrcgntnhr from "../../assets/awrdsrcgntionhr.png"
import ourprgrmhr from "../../assets/ourprgrmhr.png"
import awrdrcgntnarrow from "../../assets/arrow-right.png"
import servicekad from "../../assets/service.png"
import leftimg from "../../assets/murpleftimg.png"
import moacont from "../../assets/moa-cont.png"
import ourpartners1 from "../../assets/sarumifarms.png"
import moafarmer from "../../assets/moa-vid-click.png"
import classes from "../MDAs MOA/mdas_moa.module.css"
import NewFooter2 from "../../Components/NewFooter2";

function MdasMoa() {



    return (
        <div>
            <UrbanHeader />
            <div className={classes.herocontainer}>
                <img src={MOA} className={classes.herobanner} />
            </div>
            <div className={classes.allkads}>
                <div className={classes.visioncard}>
                    <p className={classes.visiontxt}>VISION</p>
                    <p className={classes.visiontxt2}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur</p>
                </div>
                <div className={classes.missioncard}>
                    <p className={classes.missiontxt}>MISSION</p>
                    <p className={classes.missiontxt2}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur</p>
                </div>
            </div>
            <div>
                <div className={classes.dabout}>
                    <p className={classes.abouttext}>About</p>
                    <div className={classes.mnstry0furban}>
                        <p className={classes.murpptxt}>Ministry of <br />Agriculture</p>
                        <img src={aboutline} className={classes.hrline} />
                        <p className={classes.lorem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                            fugiat nulla pariatur</p>
                    </div>


                </div>
            </div>

            <div>
                <img src={moafarmer} className={classes.aerialview} />
            </div>


            <div>
                <div className={classes.ourgoals}>
                    <p className={classes.goalstxt}>Our Goals</p>
                    <div className={classes.wholekad}>
                        <img src={kad1} className={classes.kad1} />
                        <img src={kad2} className={classes.kad2} />
                        <img src={kad3} className={classes.kad3} />
                    </div>
                </div>
            </div>

            <div className={classes.imgawrdrcgntn}>
                <img src={awrdrcgntn} className={classes.awrdrcgntn} />
                <div className={classes.textpart}>
                    <p className={classes.awrdsrcgtntxt}>Awards <br />
                        and Recognition</p>
                    <img src={awrdrcgntnhr} className={classes.awrdrcgntnhr} />
                    <div className={classes.viewawrds}>
                        <p>View awards</p>
                        <img src={awrdrcgntnarrow} className={classes.awrdrcgntnarrow} />
                    </div>
                </div>
            </div>

            <div className={classes.ourprogrm}>
                <p className={classes.prgrmtxt}>Our <br /> Programs</p>
                <div className={classes.ourprgrmtxt}>
                    <p className={classes.txt1}>reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                    <img src={ourprgrmhr} className={classes.ourprgrmhr} />
                    <p className={classes.txt2}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in </p>
                </div>
            </div>
            <div>
                <img src={servicekad} className={classes.servicekad} />
            </div>

            <div className={classes.leftrightcnt}>
            <img src={leftimg} className={classes.murpleft} />
            <img src={moacont} className={classes.murpright} />
            </div>

            <div className={classes.ourpatnas}>
                <p className={classes.patnatxt}>Our <br/>Partners</p>
                <div className={classes.thepatnas}>
                <img src={ourpartners1} className={classes.ourpartners1} />
                
            </div>
            </div>
<NewFooter2 />
        </div>
    )
}
export default MdasMoa;