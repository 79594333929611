import React, { useState, useEffect } from "react";
import classes from './Legends.module.css'
import { useNavigate } from "react-router-dom";
import { Button, Carousel } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import Obasanjo from '../../assets/legends-icon/obasanjo.png'
import Obey from '../../assets/legends-icon/obey.png'
import Bankole from '../../assets/legends-icon/bankole.png'
import Soyinka from '../../assets/legends-icon/soyinka.png'
import Shonekan from '../../assets/legends-icon/shonekan.png'
import Koya from '../../assets/legends-icon/koya.png'
import Jacobs from '../../assets/legends-icon/jacobs.png'
import Odunlade from '../../assets/legends-icon/odunlade.png'
import footerLogo from "../../assets/Footer-logo.svg"
import NewFooter from "../../Components/NewFooter";
import NewFooter2 from "../../Components/NewFooter2";

// function App() {
const Legends = () => {
  const navigate = useNavigate();

const handleNavigate = () => {
  navigate('/legend_details');
}


  return (
    <div>

      <HeaderNav />

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <h1>OGUN STATE LEGENDS</h1>
          <div className={classes.imageContainer}>
            <img
              src={Obasanjo}
              alt="Obasanjo"
              style={{ objectFit: 'cover', width: "100%", height: "100%", marginTop: 39 }}
            />
            <div className={classes.overlay}>
              <div className={classes.overlaySection}>
                <h5 className={classes.text}>CHIEF<br />OLUSEGUN OBASANJO</h5>
                <p className={classes.subText}>Chief Olusegun Matthew Okikiola Ogunboye Aremu Obasanjo GCFR is a Nigerian retired military officer and statesman who served as 
                  Nigeria's head of state from 1976 to 1979 and later as its president from 1999 to 2007.</p>
              </div>
              <div className={classes.button} onClick={handleNavigate}>
              <p className={classes.buttonText}>Read more → </p>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <div className={classes.imageContainer}>
            <img
              src={Obey}
              alt="Obey"
              style={{ objectFit: 'cover', width: "100%", height: "100%", marginTop: 39 }}
            />
            <div className={classes.overlay}>
              <div className={classes.overlaySection}>
                <h5 className={classes.text}>EBENEZER OBEY</h5>
                <p className={classes.subText}>Obey was born on 3 April 1942 to an Egba–Yoruba ethnic background family. Obey, whose real names are Ebenezer Remilekun Aremu Olasupo Fabiyi, was born in Idogo, Ogun State, Nigeria of Egba-Yoruba ethnic background. </p>
              </div>
              <div className={classes.button}>
              <p className={classes.buttonText}>Read more → </p>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <div className={classes.imageContainer}>
            <img
              src={Bankole}
              alt="Bankole"
              style={{ objectFit: 'cover', width: "100%", height: "100%", marginTop: 39 }}
            />
            <div className={classes.overlay}>
              <div className={classes.overlaySection}>
                <h5 className={classes.text}>DIMEJI BANKOLE</h5>
                <p className={classes.subText}>Sabur Oladimeji "Dimeji" Bankole (born 14 November 1969) is a Nigerian politician who served as the 11th Speaker of the House of Representatives of Nigeria. Elected at the age of 37, he is the youngest Speaker in the history of Nigeria. </p>
              </div>
              <div className={classes.button}>
              <p className={classes.buttonText}>Read more → </p>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <div className={classes.imageContainer}>
            <img
              src={Soyinka}
              alt="Soyinka"
              style={{ objectFit: 'cover', width: "100%", height: "100%", marginTop: 39 }}
            />
            <div className={classes.overlay}>
              <div className={classes.overlaySection}>
                <h5 className={classes.text}>PROFESSOR <br /> WOLE SOYINKA</h5>
                <p className={classes.subText}>Soyinka was born into a Yoruba family in Abeokuta. In 1954, he attended Government College in Ibadan, and subsequently University College Ibadan and the University of Leeds in England.</p>
              </div>
              <div className={classes.button}>
              <p className={classes.buttonText}>Read more → </p>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <div className={classes.imageContainer}>
            <img
              src={Shonekan}
              alt="Shonekan"
              style={{ objectFit: 'cover', width: "100%", height: "100%", marginTop: 39 }}
            />
            <div className={classes.overlay}>
              <div className={classes.overlaySection}>
                <h5 className={classes.text}>ERNEST SHONEKAN</h5>
                <p className={classes.subText}>Chief Ernest Adegunle Oladeinde Shonekan GCFR (9 May 1936 – 11 January 2022) was a Nigerian lawyer and statesman who served as the interim head of state of Nigeria from 26 August 1993 to 17 November 1993.</p>
              </div>
              <div className={classes.button}>
              <p className={classes.buttonText}>Read more → </p>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <div className={classes.imageContainer}>
            <img
              src={Koya}
              alt="Abiodun Koya"
              style={{ objectFit: 'cover', width: "100%", height: "100%", marginTop: 39 }}
            />
            <div className={classes.overlay}>
              <div className={classes.overlaySection}>
                <h5 className={classes.text}>Abiodun Koya</h5>
                <p className={classes.subText}>Born to a musical family in Ijebu-Ode, in Nigeria's Ogun State, the last of five children, Koya's father introduced classical music to his youngest daughter at the age of three.Within a few years, Koya began playing the violin and singing.</p>
              </div>
              <div className={classes.button}>
              <p className={classes.buttonText}>Read more → </p>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <div className={classes.imageContainer}>
            <img
              src={Jacobs}
              alt="Jacobs"
              style={{ objectFit: 'cover', width: "100%", height: "100%", marginTop: 39 }}
            />
            <div className={classes.overlay}>
              <div className={classes.overlaySection}>
                <h5 className={classes.text}>OLU JACOBS</h5>
                <p className={classes.subText}>Oludotun Baiyewu Jacobs, Listen (born 11 July 1942), known professionally as Olu Jacobs, is a Nigerian actor and film executive.He has starred in several British television series and international films.</p>
              </div>
              <div className={classes.button}>
              <p className={classes.buttonText}>Read more → </p>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <div className={classes.imageContainer}>
            <img
              src={Odunlade}
              alt="Odunlade"
              style={{ objectFit: 'cover', width: "100%", height: "100%", marginTop: 39 }}
            />
            <div className={classes.overlay}>
              <div className={classes.overlaySection}>
                <h5 className={classes.text}>ODUNLADE ADEKOLA</h5>
                <p className={classes.subText}>Odunlade Jonathan Adekola (born 31 December 1976) is a Nigerian actor, singer, film-maker, film producer and film director. He gained popularity and was widely known for his lead role in Ishola Durojaye's 2003 movie, Asiri Gomina Wa.</p>
              </div>
              <div className={classes.button}>
              <p className={classes.buttonText}>Read more → </p>
                </div>
            </div>
          </div>
        </div>
      </div>







      <NewFooter2/>

    </div>
  );
}

export default Legends;