import React, { useState, useEffect } from "react";
import classes from './Executives.module.css'
import { useNavigate } from "react-router-dom";
import { Button, Carousel } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import Dapo from "../../assets/nms.png";
import NewFooter2 from "../../Components/NewFooter2";

// function App() {
const ExecutivesDetails2 = () => {
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
    const latestYear = new Date().getFullYear();
    setCurrentYear(latestYear);
  }, []);



  return (
    <div>

      <HeaderNav />

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <h1>NOIMOT SALAKO OYEDELE</h1>
          <p>DEPUTY GOVERNOR OF OGUN STATE</p>
          <div className={classes.bioContainer}>
          <div className={classes.imageCont}>
          <img
            src={Dapo}
            alt="Deputy Governor of Ogun State"
            style={{ objectFit: 'contain', width: "100%", height: 500, marginTop: 39 }}
          />
          </div>
          <div className={classes.profileCont}>
          <div className={classes.topSectionLabel}>
            <h5 className={classes.labelText}>PROFILE OF THE OGUN STATE DEPUTY GOVERNOR, ENGR. NOIMOT SALAKO-OYEDELE<small>FNSE</small></h5>
            <div className={classes.profileText}>
              <p>Engr. Noimot Salako-Oyedele FNSE is an Entrepreneur, Civil Engineer and politician. Born on 8th January 1966 to late Prof. Lateef Akinola Salako and Mrs Rahmat Adebisi Salako, she hails from Ota and is a prominent daughter of Aworiland.<br /> <br />

              Noimot obtained her Bachelor of Science Degree in Civil Engineering from the University of Lagos in 1987 and an MSc. in Public Health Engineering from the prestigious Imperial College of Science and Technology, London in 1989. In addition, she has attended several courses as part of her continuous professional development.<br /> <br />
              She is a consummate multi-talented professional with over 30 years experience in Engineering Consultancy, Contracting and Real Estate Sectors. She is result-oriented and enthusiastic with a broad range of knowledge and experience in developing and implementing effective strategies in managing projects. <br /> <br />
              Noimot has worked with several organizations including Ove Arup & Partners (UK) and Grenadines Homes Limited. She was the MD/CEO of Glenwood Property Development Company Limited before becoming Deputy Governor. <br /> <br />
              In March 2019, the good people of Ogun State elected Noimot Salako-Oyedele to serve as Deputy Governor to His Excellency, Prince Dapo Abiodun MFR, making her the first Ogun State Deputy Governor of Awori extraction.<br /> <br />
              In addition to her administrative duties as Deputy Governor and member of the Ogun State Executive Council, she is the Chairman of the Ogun State Steering Committee of the World Bank funded Nigeria for Women Project and the Chairman of the Ogun State Boundary Committee.<br /> <br />
              In March 2023, she was appointed by H.E. President Muhammadu Buhari to serve as a Member of the High-Level Advisory Committee (HLAC) on Women's Economic Empowerment and Gender in Nigeria.<br /> <br />
              On May 29, 2023, Her Excellency Engr. Noimot Salako-Oyedele FNSE was sworn in for a second term as Deputy Governor of Ogun State.<br /> <br />
              She was conferred with the title “Beere of Aworiland” in December 2023 by the Olota of Ota, His Royal Highness, Oba (Prof) Abdulkabir Adeyemi Obalanlege.<br /> <br />
              Engr. Noimot Salako-Oyedele, a devout Muslim is married to Alhaji Bode Oyedele FCA, an APC chieftain who retired as Permanent Secretary in the Lagos State Civil Service and is blessed with wonderful children. <br /> <br />
              </p>
              
            
            </div>
          </div>
          </div>
          </div>
          
        </div>
      </div>

   


     













      <NewFooter2 />
    </div>
  );
}

export default ExecutivesDetails2;