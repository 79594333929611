import React, { useState, useEffect } from "react";
import classes from './Executives.module.css'
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Carousel } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import Dapo from "../../assets/executives-icon/dapoo.png";
import NewFooter2 from "../../Components/NewFooter2";

// function App() {
const ExecutivesDetails = () => {
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
    const latestYear = new Date().getFullYear();
    setCurrentYear(latestYear);
  }, []);

  const location = useLocation();
  const { data } = location.state;

  return (
    <div>

      <HeaderNav />

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <h1>{data?.commisioners_name}</h1>
          <p>{data?.portfolio === "Governor" ? "GOVERNOR OF OGUN STATE" : data?.portfolio}</p>
          <div className={classes.bioContainer}>
          <div className={classes.imageCont}>
          <img
            src={data?.commisioners_image}
            alt="Ogun State Governor"
            style={{ objectFit: 'cover', width: "100%", height: 500, marginTop: 39 }}
          />
          </div>
          <div className={classes.profileCont}>
          <div className={classes.topSectionLabel}>
            <h5 className={classes.labelText}>PROFILE OF {data?.commisioners_name}</h5>
            <div className={classes.profileText}>
              <p>
                {data?.commisioners_profile}
              </p>
            </div>
          </div>
          </div>
          </div>
          
        </div>
      </div>

   


     













      <NewFooter2 />
    </div>
  );
}

export default ExecutivesDetails;